<template>
  <div v-if="row.n_type_original==1  ">
    <div v-if="!empty(row.hssContent)" class="hssContentContainer">
      <div class="inlineDiv top subscriberAdditionalInfoTitleContainer">HSS</div>
      <div class="inlineDiv">
        <hssDataContent :row="row"></hssDataContent>
      </div>
    </div>

    <div v-if="!empty(row.ldapContent)">
      <div class="inlineDiv top subscriberAdditionalInfoTitleContainer">LDAP</div>
      <div class="inlineDiv">
        <ldapContent :row="row"></ldapContent>
      </div>
    </div>
  </div>
  <div v-else-if="row.n_type_original==2">
    <div v-for="(subscriber, i) in row.subscriberList" :key="i">
      :subLength="subLength+subscriber.length"
      <span
        v-if="subLength<60 && i < row.subscriberList.length-1"
      >{{subscriber}},</span>
    </div>
    <span v-if="subLength>60">...</span>
  </div>
</template>
<script>
import hssDataContent from "@/components/legacy/hssDataContent.vue";
import ldapContent from "@/components/legacy/ldapContent.vue";
// import {count} from "echarts/types/src/component/dataZoom/history";
export default {
  components: { hssDataContent, ldapContent },
  props: ["header", "row", "currentTable"],
  data() {
    return {
      // count: count,
      subLength: 0
    };
  },
};
</script>